<div class="col-12">
    <p attr.aria-label="{{question.Text}}">
        {{question.Text}}<span [ngClass]="question.IsMandatory? 'required-field': ''"></span>
        <app-video-buttons-small id="{{question.QuestionId}}" title="" writtenExplanation="{{question.Tooltip}}" url="{{question.VideoUrl}}" size="0"></app-video-buttons-small>
    </p>
</div>
<div class="col-12" style = "padding-bottom: 20px;">    
    <input type="text" class="email" maxlength=250 #emailref="ngModel" pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,5}$" [(ngModel)]="question.Answer" (keyup)="answerQuestion(question.Answer)" (keydown.enter)="answerQuestion(question.Answer)" (focusout)="answerQuestion(question.Answer)" [placeholder]="" tabindex="0">
    <div class="invalid" [hidden]="!emailref.errors?.pattern">
        Invalid Email
    </div> 
    <div *ngIf="question.IsMandatory && (this.question.Answer === undefined || this.question.Answer === null || this.question.Answer.length == 0) && (emailref.touched || emailref.dirty)" style="text-align: left;">
        <div class="invalid" >
            This field is required
        </div> 
    </div> 
</div>
