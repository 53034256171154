<div #questionForm class="central" id="questionForm">
    <div class="container">
        <div>
            <div class="body-container">
                <br/>
                <br/>
                <div *ngIf="this.sectionToDisplay !== null && this.sectionToDisplay !== undefined">
                    <div class="custom-title" >
                        <label attr.aria-label="{{sectionToDisplay.SectionName}}">{{sectionToDisplay.SectionName}}</label>
                    </div>
                    <br/>
                    <br/>
                    <p>
                        {{sectionToDisplay.SectionDescription}}
                    </p>
                    
                    <div *ngFor="let question of sectionToDisplay.Questions; index as i;">
                        <div style="text-align: left;">
                            <span [ngSwitch]="question.FieldType">
                                <div *ngSwitchCase="fieldType.Text">
                                    <app-text-field [question]="question" (answered)="saveAnswer(question, $event.answer)"></app-text-field>
                                </div>
                                <div *ngSwitchCase="fieldType.BigText">
                                    <app-big-text-field [question]="question" (answered)="saveAnswer(question, $event.answer)"></app-big-text-field>
                                </div>
                                <div *ngSwitchCase="fieldType.NumberText">
                                    <app-number-text-field [question]="question" (answered)="saveAnswer(question, $event.answer)"></app-number-text-field>
                                </div>
                                <div *ngSwitchCase="fieldType.Date">
                                    <app-date-selector [question]="question" (answered)="saveAnswer(question, $event.answer)"></app-date-selector>
                                </div>
                                <div *ngSwitchCase="fieldType.Radio">
                                    <app-radio-selector [question]="question" (answered)="saveAnswer(question, $event.answer)"></app-radio-selector>
                                </div>
                                <div *ngSwitchCase="fieldType.Checkbox">
                                    <app-checkbox-selector [question]="question" (answered)="saveAnswer(question, $event.answer)"></app-checkbox-selector>
                                </div>
                                <div *ngSwitchCase="fieldType.Dropdown">
                                    <app-autocomplete-single-dropdown [question]="question" (answered)="saveAnswer(question, $event.answer)"></app-autocomplete-single-dropdown>
                                </div>
                                <div *ngSwitchCase="fieldType.Email">
                                    <app-email-field [question]="question" (answered)="saveAnswer(question, $event.answer)"></app-email-field>
                                </div>
                                <div *ngSwitchCase="fieldType.FileUpload">
                                    <app-file-upload-field [question]="question" (answered)="saveFile(question, $event.files)" (removed)="removeFile(question, $event.fileIndex)"></app-file-upload-field>
                                </div>
                            </span>
                        </div>
                    </div>
                    <div class="confirmationBox">
                        <div class="col-12">
                        <br/>
                        <br/>
                            <div class="checkbox-row">
                                <div class="checkbox-container">
                                    <label class="radio-container" tabindex="0">
                                        <input type="checkbox" [(ngModel)]="this.sectionToDisplay.IsConfirmed"  (click)="confirmSection(sectionToDisplay, checkbox.checked);" style="display:none;" [value]="0" #checkbox/>
                                        <span class="checkmark" style="display: inline-block; width:25px;"></span>
                                        <span attr.aria-label="{{confirmationBoxText}}" title="{{confirmationBoxText}}" class="confirmation required-field">{{confirmationBoxText}}</span>
                                    </label>
                                </div> 
                                <div style="text-align: left;">
                                    <div class="invalid" *ngIf="this.sectionToDisplay.IsConfirmed !== undefined && checkbox.checked === false">
                                        This field is required
                                    </div> 
                                </div> 
                            </div>
                        </div>
                    </div>
                </div>
                <div class="navigation-container">
                    <div class="col-12" style="text-align: center;">
                        <br/>
                        <button *ngIf="this.isNextButtonDisplayed()" class="btn proceed-button" (click)="continue(sectionToDisplay!)" aria-label="Next Page" tabindex="0" [disabled]="isSubmitting$ | async">
                            <span *ngIf="isSubmitting$ | async" class="spinner-border spinner-border-sm" style="display: inline-block" role="status" aria-hidden="true"></span>
                            Next
                        </button>
                        <button *ngIf="this.isLastSection && this.sectionToDisplay !== null" class="btn proceed-button" (click)="continue(sectionToDisplay!)" aria-label="Next Page" tabindex="0" [disabled]="isSubmitting$ | async">
                            <span *ngIf="isSubmitting$ | async" class="spinner-border spinner-border-sm" style="display: inline-block" role="status" aria-hidden="true"></span>
                            Submit
                        </button>
                        <jw-modal id="submit-failure-modal" url="text">
                            <div>
                                <label style="margin-bottom: 50px;" class="input-label" aria-label="Unfortunately, we have encountered an issue when trying to process the information provided. We are investigating this now, so there is no need to contact us. Please try again in a couple of hours, by which time we hope to have fixed the problem. Apologies for any inconvenience caused.">Unfortunately, we have encountered an issue when trying to process the information provided. We are investigating this now, so there is no need to contact us. Please try again in a couple of hours, by which time we hope to have fixed the problem. Apologies for any inconvenience caused.</label>
                                <br/>
                                <button class="video-modal-button title-font dropbtn" aria-label="Close" (click)="closeModal('submit-failure-modal')" tabindex="0">Close</button>
                            </div>
                        </jw-modal>
                        <jw-modal id="validation-failure-modal" url="text">
                            <div>
                                <label style="margin-bottom: 50px;" class="input-label" aria-label="There has been an issue submitting your details. Please check all the text boxes have been filled in correctly." >There has been an issue submitting your details. Please check all the text boxes have been filled in correctly.</label>
                                <br/>
                                <button class="video-modal-button title-font dropbtn" aria-label="Close" (click)="closeModal('validation-failure-modal')" tabindex="0">Close</button>
                            </div>
                        </jw-modal>
                        <jw-modal id="failed-to-authenticate-modal" url="text">
                            <div>
                                <label style="margin-bottom: 250px;" aria-label="Unfortunately the details you have provided does not match the information that we hold on file for you. Please click on 'try again' and enter the correct details. If you need to speak to us, please contact the case handler. Their contact details are provided at the top of the letter we sent to you." class="input-label">Unfortunately the details you have provided does not match the information that we hold on file for you. Please click on "try again" and enter the correct details. If you need to speak to us, please contact the case handler. Their contact details are provided at the top of the letter we sent to you.</label>
                                <br/>
                                <button class="video-modal-button title-font dropbtn" (click)="closeModal('failed-to-authenticate-modal');" tabindex="0">Try again</button>
                            </div>
                        </jw-modal>
                        <jw-modal id="bad-request-modal" url="text">
                            <div>
                                <label style="margin-bottom: 50px;" aria-label="Unfortunately, we have encountered an issue when trying to process the information provided. We are investigating this now, so there is no need to contact us. Please try again in a couple of hours, by which time we hope to have fixed the problem. Apologies for any inconvenience caused." class="input-label">Unfortunately, we have encountered an issue when trying to process the information provided. We are investigating this now, so there is no need to contact us. Please try again in a couple of hours, by which time we hope to have fixed the problem. Apologies for any inconvenience caused.</label>
                                <br/>
                                <button class="video-modal-button title-font dropbtn" (click)="refreshPage()" tabindex="0">Close</button>
                            </div>
                        </jw-modal>
                        <jw-modal id="session-expired-modal" url="text">
                            <div>
                                <label style="margin-bottom: 50px;" aria-label="Your session has expired due to inactivity." class="input-label">Your session has expired due to inactivity.</label>
                                <br/>
                                <button class="video-modal-button title-font dropbtn" (click)="refreshPage()" tabindex="0">Close</button>
                            </div>
                        </jw-modal>
                        <jw-modal id="recaptcha-failed-modal" url="text">
                            <div>
                                <label style="margin-bottom: 50px;" aria-label="Unfortunately, the network you are using to reach this application is not able to validate your information. Please try again on another device or network. Apologies for any inconvenience caused." class="input-label">Unfortunately, the network you are using to reach this application is not able to validate your information. Please try again on another device or network. Apologies for any inconvenience caused.</label>
                                <br/>
                                <button class="video-modal-button title-font dropbtn" (click)="closeModal('recaptcha-failed-modal');" tabindex="0">Close</button>
                            </div>
                        </jw-modal>
                        <jw-modal id="empty-answers-modal" url="text">
                            <div>
                                <label style="margin-bottom: 50px;" aria-label="We see that you have left a few questions on this page unanswered. If you would like to answer them, please select 'Go back and Complete'. If you want to leave them unanswered and continue to the next set of questions, please select 'Skip and Continue'. The more information you provide us with at this stage, the quicker we will be able to progress this claim for you. Please note that if there is the option to upload images/documents on the page, and you do not wish to, you can simply skip and continue to the next set of questions. Would you like to go back and complete or skip and continue to the next set of questions?" class="input-label">We see that you have left a few questions on this page unanswered. If you would like to answer them, please select 'Go back and Complete'. If you want to leave them unanswered and continue to the next set of questions, please select 'Skip and Continue'. The more information you provide us with at this stage, the quicker we will be able to progress this claim for you. Please note that if there is the option to upload images/documents on the page, and you do not wish to, you can simply skip and continue to the next set of questions. Would you like to go back and complete or skip and continue to the next set of questions?</label>
                                <br/>
                                <div class="button-container">
                                    <button class="btn btn-success btn-back" (click)="closeModal('empty-answers-modal');" tabindex="0">Go back and Complete</button>
                                    <button class="btn btn-danger btn-continue" (click)="submit(this.sectionToDisplay!);" tabindex="0" [disabled]="isSubmitting$ | async">
                                        <span *ngIf="isSubmitting$ | async" class="spinner-border spinner-border-sm" style="display: inline-block" role="status" aria-hidden="true"></span>
                                        Skip and Continue
                                    </button>
                                </div>
                            </div>
                        </jw-modal>
                    </div>
                </div>
                
            </div>
            
            <br/>
            <br/>
        </div>
    </div>
    
</div>