import { Answer } from "./answer.model";
import { MappingType } from "./submittedanswer.model";
import { UploadedFile } from "./uploadedfile.model";

export class Question {
    public QuestionId: number;
    public OrderNumber: number;
    public Text: string;
    public Tooltip?: string;
    public FieldType: FieldType;
    public ValidationText: string;
    public SectionId: number;
    public MappingType?: MappingType;
    public Answers?: Answer[];
    public Answer: string = "";
    public Files: UploadedFile[] | null | undefined;
    public IsMandatory: Boolean;
    public VideoUrl?: string;

    constructor(questionId: number, orderNumber: number, text: string, fieldType: number, validationText: string, sectionId: number, tooltip?: string, answers?: Answer[], mappingType?: MappingType, isMandatory?: Boolean, tooltipVideoUrl?: string){
        this.QuestionId = questionId;
        this.OrderNumber = orderNumber;
        this.Text = text;
        this.Tooltip = tooltip;
        this.FieldType = fieldType;
        this.ValidationText = validationText;
        this.Answers = answers;
        this.SectionId = sectionId;
        this.MappingType = mappingType;
        this.IsMandatory = isMandatory? isMandatory: false;
        this.VideoUrl = tooltipVideoUrl;
    }
}

export enum FieldType {
    Text,
    BigText,
    NumberText,
    Date,
    Radio,
    Dropdown,
    FileUpload,
    Email,
    Checkbox
}