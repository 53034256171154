import { Component, OnInit, Input, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import { Question } from 'src/app/models/question.model';


@Component({
  selector: 'app-number-text-field',
  templateUrl: './number-text-field.component.html',
  styleUrls: ['./number-text-field.component.css']
})
export class NumberTextFieldComponent implements OnInit {

  @Input() 
  question!: Question;

  @Output() 
  answered = new EventEmitter<any>();
  
  constructor() {
   }

  ngOnInit(): void {
  }

  answerQuestion(answer: string){
    this.answered.emit({answer});
  }

}
