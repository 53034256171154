import { Component, OnInit, Input, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import { Question } from 'src/app/models/question.model';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-text-field',
  templateUrl: './text-field.component.html',
  styleUrls: ['./text-field.component.css']
})
export class TextFieldComponent implements OnInit {
  charactersLimit = Number(environment.TextBoxCharactersLimit);
  currentChars = 0;

  @Input() 
  question!: Question;

  @Output() 
  answered = new EventEmitter<any>();
  
  constructor() {
  }

  ngOnInit(): void {
  }

  answerQuestion(answer: string){
    this.answered.emit({answer});
    this.currentChars = answer.length;
  }

}
