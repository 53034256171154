<div class="col-12">
    <p attr.aria-label="{{question.Text}}">
        {{question.Text}}<span [ngClass]="question.IsMandatory? 'required-field': ''"></span>
        <app-video-buttons-small id="{{question.QuestionId}}" title="" writtenExplanation="{{question.Tooltip}}" url="{{question.VideoUrl}}" size="0"></app-video-buttons-small>
    </p>
</div>
<div class="col-12 input-container">    
    <input type="file"
               class="file-input"
               accept="{{allowedFileExtensions}}"
               (input)="handleFileInput($event, fileUpload)"
               #fileUpload>

    <div class="file-upload">
        <button mat-mini-fab color="primary" class="upload-button"
        (click)="fileUpload.click()">
            <mat-icon>attach_file</mat-icon>
        </button>
        <div *ngIf="question.Files !== undefined && question.Files !== null" style="text-align: left;">
            <div *ngFor="let file of question.Files; index as i;">
                <button mat-mini-fab color="primary" class="remove-button"
                (click)="removeFile(question, i)">
                    <mat-icon>delete</mat-icon>
                </button>
                <span>{{file.FileName}}</span>
            </div>
        </div>
        <br/>
        <div class="file-upload-limitations">Please note that you can upload a maximum of {{maxFileCount}} files and each file has a size limit of {{maxFileSizeMBytes+"MB"}}. The file types you can upload are: {{this.allowedFileExtensionsArray.join(', ')}}. If you want to send us any other documents and are unable to upload them here, please email them to your case handler. Their contact details can be found on the letter sent to you</div>
    </div>
    <div *ngIf="question.IsMandatory && (question.Files === null || question.Files?.length === 0)" style="text-align: left;">
        <div class="invalid" >
            This field is required
        </div> 
    </div>
    <div *ngIf="this.IsFileSizeExceeded" style="text-align: left;">
        <div class="invalid" >
            File size exceeded {{maxFileSizeMBytes}}MB
        </div> 
    </div>
    <div *ngIf="this.IsTooManyFiles" style="text-align: left;">
        <div class="invalid" >
            You can upload a maximum of {{maxFileCount}} files
        </div> 
    </div>
    <div *ngIf="this.IsInvalidFileExtension" style="text-align: left;">
        <div class="invalid" >
            Invalid file extension
        </div> 
    </div>
</div>

