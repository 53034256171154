<div class="central">
    <div class="container">
        <div class="body-container">
            <br/>
            <br/>
        <div class="custom-title" >
            <label aria-label="QUICK SECURITY CHECK">QUICK SECURITY CHECK</label>
        </div>
        <br/>
        <br/>
                <div class="row ">
                   <div class="col-md-12 mb-3">
                        <label for="name" aria-label="Please enter your full name, excluding your title (i.e. excluding Mrs/Mr, etc)">
                            
                            <div style="display: unset" class="required-field">Please enter your full name, excluding your title (i.e. excluding Mrs/Mr, etc)</div>
                            <app-video-buttons-small id="validation-question1" title="" writtenExplanation="Please enter your full name, excluding your title (i.e. excluding Mrs/Mr, etc)" url="https://player.vimeo.com/video/798751349?h=d2fb2e2bcd&autoplay=1" size="0"></app-video-buttons-small>                            
                        </label>
                        
                        <input type="text" class="form-control" id="name" pattern="[a-zA-Z0-9-\x27 ]*" maxlength="250" #fullnameref="ngModel" ngModel [(ngModel)]="validationDetails.Fullname" (ngModelChange)="this.updateProgressBarForQuestion()" placeholder="Type here...">
                        <div *ngIf="fullnameref.errors &&(fullnameref.touched || fullnameref.dirty)">
                            <div class="invalid" [hidden]="!fullnameref.errors.pattern">
                                Invalid full name
                            </div> 
                        </div> 
                    </div>
                    <div class="col-md-12 mb-3">
                        <label for="referenceNumber" aria-label="Please type in your reference number. You can find this at the top of the letter we sent to you. Please don't include any letters. For example if your reference number is '12345/12345-ABC' please just use '12345/12345'" >
                            <div style="display: unset" class="required-field">Please type in your reference number. You can find this at the top of the letter we sent to you. Please don't include any letters. For example if your reference number is '12345/12345-ABC' please just use '12345/12345'</div>
                            <app-video-buttons-small id="validation-question2" title="" writtenExplanation="Please enter your reference number. You can find this at the top of the letter that we have sent to you" url="https://player.vimeo.com/video/798751330?h=dc4634d5e6&autoplay=1" size="0"></app-video-buttons-small>   
                        </label>
                        <input type="text" class="form-control" id="referenceNumber" pattern="[0-9/]*"   maxlength="250" #referencenumberref="ngModel" ngModel [(ngModel)]="validationDetails.CaseReference" (ngModelChange)="this.updateProgressBarForQuestion()" placeholder="Type here...">
                        <div *ngIf="referencenumberref.errors &&(referencenumberref.touched || referencenumberref.dirty)">
                            <div class="invalid" [hidden]="!referencenumberref.errors.pattern">
                                Invalid reference number
                            </div> 
                        </div>
                    </div>
                    <div class="col-md-12 mb-3">
                        <label for="registrationNumber" aria-label="Please type in the registration number of the vehicle you were driving at the time of the incident (please enter as one number with no spaces)">
                            <div style="display: unset" class="required-field">Please type in the registration number of the vehicle you were driving at the time of the incident (please enter as one number with no spaces)</div>
                            <app-video-buttons-small id="validation-question3" title="" writtenExplanation="Please enter the registration number of the vehicle you were driving at the time of the incident" url="https://player.vimeo.com/video/798751304?h=add071a064&autoplay=1" size="0"></app-video-buttons-small>   
                        </label>
                        <input type="text" class="form-control" id="registrationNumber" pattern="[a-zA-Z0-9]*"   maxlength="250" #registrationnumberref="ngModel" ngModel [(ngModel)]="validationDetails.RegistrationNumber" (ngModelChange)="this.updateProgressBarForQuestion()" placeholder="Type here...">
                        <div *ngIf="registrationnumberref.errors && (registrationnumberref.touched || registrationnumberref.dirty)">
                            <div class="invalid" [hidden]="!registrationnumberref.errors.pattern">
                                Invalid registration number
                            </div> 
                        </div>
                    </div>
                </div>
        </div>
    <div class="navigation-container">
        <div style="height: 100px;">
        <div *ngIf=" fullnameref.value !== '' && referencenumberref.value !== '' && registrationnumberref.value !== '' && !fullnameref.errors?.pattern && !referencenumberref.errors?.pattern && !registrationnumberref.errors?.pattern" class="row justify-content-center" >
            
            <div class="col-12" style="text-align: center;">
                <br/>
                <button class="btn proceed-button" id="submit-validation-button" (click)="submitForm()" aria-label="Next Page" tabindex="0" [disabled]="isLoading$ | async">
                    <span *ngIf="isLoading$ | async" class="spinner-border spinner-border-sm" style="display: inline-block" role="status" aria-hidden="true"></span>
                    Submit<i class="bi bi-chevron-right"></i>
                </button>
                <jw-modal id="failed-to-authenticate-modal" url="text">
                    <div>
                        <label style="margin-bottom: 50px;" aria-label="Unfortunately the details you have provided does not match the information that we hold on file for you. Please click on 'try again' and enter the correct details. If you need to speak to us, please contact the case handler. Their contact details are provided at the top of the letter we sent to you." class="input-label">Unfortunately the details you have provided does not match the information that we hold on file for you. Please click on "try again" and enter the correct details. If you need to speak to us, please contact the case handler. Their contact details are provided at the top of the letter we sent to you.</label>
                        <br/>
                        <button class="video-modal-button title-font dropbtn" (click)="refreshPage();" tabindex="0">Try again</button>
                    </div>
                </jw-modal>
                <jw-modal id="bad-request-modal" url="text">
                    <div>
                        <label style="margin-bottom: 50px;" aria-label="Unfortunately, we have encountered an issue when trying to process the information provided. We are investigating this now, so there is no need to contact us. Please try again in a couple of hours, by which time we hope to have fixed the problem. Apologies for any inconvenience caused." class="input-label">Unfortunately, we have encountered an issue when trying to process the information provided. We are investigating this now, so there is no need to contact us. Please try again in a couple of hours, by which time we hope to have fixed the problem. Apologies for any inconvenience caused.</label>
                        <br/>
                        <button class="video-modal-button title-font dropbtn" (click)="closeModal('bad-request-modal');" tabindex="0">Close</button>
                    </div>
                </jw-modal>
                <jw-modal id="recaptcha-failed-modal" url="text">
                    <div>
                        <label style="margin-bottom: 50px;" aria-label="Unfortunately, the network you are using to reach this application is not able to validate your information. Please try again on another device or network. Apologies for any inconvenience caused." class="input-label">Unfortunately, the network you are using to reach this application is not able to validate your information. Please try again on another device or network. Apologies for any inconvenience caused.</label>
                        <br/>
                        <button class="video-modal-button title-font dropbtn" (click)="closeModal('recaptcha-failed-modal');" tabindex="0">Close</button>
                    </div>
                </jw-modal>
                <jw-modal id="validation-failed-modal" url="text">
                    <div>
                        <label style="margin-bottom: 50px;" aria-label="These fields are required." class="input-label">These fields are required.</label>
                        <br/>
                        <button class="video-modal-button title-font dropbtn" (click)="closeModal('validation-failed-modal');" tabindex="0">Close</button>
                    </div>
                </jw-modal>
            </div>
        </div>
    </div>
</div>
    </div>
    
</div>