<div class="col-12">
    <p attr.aria-label="{{question.Text}}">
        {{question.Text}} <span [ngClass]="question.IsMandatory? 'required-field': ''"></span>
        <app-video-buttons-small id="{{question.QuestionId}}" title="" writtenExplanation="{{question.Tooltip}}" url="{{question.VideoUrl}}" size="0"></app-video-buttons-small>
    </p>
</div>
<div class="col-12" style="padding-bottom: 20px;">
    <form >
    <div class="row justify-content-center">
        <div class="col-12">
            <input style="width:100%" type="text" placeholder="Pick one" #matInput [formControl]="autoCompleteControl" [matAutocomplete]="auto" (keyup)="answerQuestion(answer)" >
            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                <mat-option *ngFor="let option of filteredOptions | async;" [value]="option" >
                    {{option}}
                </mat-option>
            </mat-autocomplete>
        </div>
    </div>  
    </form>
    <div *ngIf="question.IsMandatory && this.question.Answer !== undefined && (this.question.Answer === null || this.question.Answer.length === 0)  " style="text-align: left;">
        <div class="invalid" >
            This field is required
        </div> 
    </div> 
    <div *ngIf="matInput.value !== '' && availableValues.includes(matInput.value) === false" style="text-align: left;">
        <div class="invalid" >
            Invalid value selected
        </div> 
    </div> 
</div>