import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { isLoggedInSelector } from '../state/app.selectors';
import { IAppState } from '../state/app.state';

@Injectable({
  providedIn: 'root'
})
export class UserValidationGuard implements CanActivate {

  userValidated:boolean;
  constructor(private store: Store<IAppState>, private router:Router) {
    this.userValidated = false;
  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      if(this.userValidated){
        return true;
      }
      else {
        this.router.navigateByUrl('/welcome');
        return false;
      }
  }
  
}
