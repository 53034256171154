import { Injectable } from '@angular/core';
import { IAppState } from '../state/app.state';
import { Store } from '@ngrx/store';
import { AppComponent } from '../app.component';
declare var Nanobar: any;
@Injectable({
  providedIn: 'root'
})
export class ProgressbarService {
  constantSize:number;
  nanobarOptions = {
    classname: 'progress-bar',
    id: 'nanobar',
    target: document.getElementById('progress-bar-container')
  };
  protected nanobar: any = new Nanobar( this.nanobarOptions );

  constructor(private appComponent: AppComponent){ 
    this.constantSize=0;
  }

  changeConstantSize(size: number){
    this.constantSize += size;
    this.nanobar.go(this.constantSize);
  }

  changeTempSize(size: number){
    let newSize = this.constantSize + size;
    this.nanobar.go(newSize);
  }

  changeSize(size: number){
    this.constantSize = size;
    this.nanobar.go(size);
  }
}
