<div class="col-12">
    <p attr.aria-label="{{question.Text}}">
        {{question.Text}}<span [ngClass]="question.IsMandatory? 'required-field': ''"></span>
        <app-video-buttons-small id="{{question.QuestionId}}" title="" writtenExplanation="{{question.Tooltip}}" url="{{question.VideoUrl}}" size="0"></app-video-buttons-small>
    </p>
</div>
<div class="col-12">
    <textarea rows="5" class="big-text" maxlength="{{charactersLimit}}" #textbox="ngModel" class="form-control" type="text" maxlength=500 [(ngModel)]="question.Answer" (keyup)="answerQuestion(question.Answer)" (keydown.enter)="answerQuestion(question.Answer)" (focusout)="answerQuestion(question.Answer)" tabindex="0"></textarea>
    <div id="character-count">
        <span>{{currentChars}}/{{charactersLimit}}</span>
    </div>
    <div *ngIf="question.IsMandatory && (this.question.Answer === undefined || this.question.Answer === null || this.question.Answer.length == 0) && (textbox.touched || textbox.dirty)" style="text-align: left;">
        <div class="invalid">
            This field is required
        </div> 
    </div> 
</div>