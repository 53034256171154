import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { BnNgIdleService } from 'bn-ng-idle';
import { Observable } from 'rxjs';
import { Question, FieldType } from 'src/app/models/question.model';
import { Section } from 'src/app/models/section.model';
import { SubmittedAnswer } from 'src/app/models/submittedanswer.model';
import { UploadedFile } from 'src/app/models/uploadedfile.model';
import { ValidationDetails } from 'src/app/models/validationdetails.model';
import { HttpService } from 'src/app/services/http.service';
import { ValidationService } from 'src/app/services/validation.service';
import { isLoadingSelector, isSubmittingSelector, sectionsSelector, validationDetailsSelector } from 'src/app/state/app.selectors';
import { IAppState } from 'src/app/state/app.state';
import * as Actions from '../../state/app.actions';
import { ModalService } from '../_shared/_modal/modal.service';
import { environment } from 'src/environments/environment';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { AppComponent } from 'src/app/app.component';
import { ProgressbarService } from 'src/app/services/progressbar.service';
declare var $: any;

@Component({
  selector: 'app-question-form',
  templateUrl: './question-form.component.html',
  styleUrls: ['./question-form.component.css']
})
export class QuestionFormComponent implements OnInit {

  public fieldType=FieldType;
  userDetails$: Observable<ValidationDetails>;
  sections$: Observable<Array<Section> | null>;
  validationDetails: ValidationDetails = {};
  isSubmitting$: Observable<Boolean>;
  submittedAnswers: SubmittedAnswer[] = new Array<SubmittedAnswer>();
  confirmationBoxText: String;
  sectionToDisplay: Section | null = null;
  isLastSection: Boolean = false;
  userDetails!: ValidationDetails | null;
  token: String = "";
  questionsCount: number = 0;
  sections!: Section[] | null;
  @ViewChild("checkbox") checkboxRef!: ElementRef<HTMLInputElement>;
  
  constructor(private modalService: ModalService, private reCaptchaV3Service: ReCaptchaV3Service, private bnIdle: BnNgIdleService, private store: Store<IAppState>, private validationService: ValidationService, private httpService: HttpService, private progressbarService: ProgressbarService) {
    this.userDetails$ = this.store.pipe(select(validationDetailsSelector));
    this.sections$ = this.store.pipe(select(sectionsSelector));
    this.isSubmitting$ = this.store.pipe(select(isSubmittingSelector));
    this.confirmationBoxText = "Confirm your answers to proceed.";
    this.bnIdle.startWatching(Number(environment.sessionLengthSec)).subscribe((res) => {
      if(res) {
          this.logOff("Session expired.");
      }
    });
   }

  ngOnInit(): void {
    this.scrollTop();
    this.progressbarService.changeSize(15);
    this.userDetails$.subscribe(userDetails => {
      if(this.sectionToDisplay === null){
        this.userDetails = userDetails;
        this.store.dispatch(Actions.getQuestionsRequest());
      }
    });
    this.sections$.subscribe(sections => {
      this.sectionToDisplay = sections?.filter( (x) => x.IsSubmitted === undefined || x.IsSubmitted === null || x.IsSubmitted === false)[0]!;
      this.isLastSection = sections?.filter( (x) => x.IsSubmitted === undefined || x.IsSubmitted === null || x.IsSubmitted === false).length! === 1;
      this.updateBackground();
      this.questionsCount = sections?.map((x) => x.Questions.length).reduce((a, b) => {return a+b;})!;
    });
  }
  
  updateProgressBarForQuestion(){
    let answeredSectionQuestionsCount = this.sectionToDisplay?.Questions.map((x) => ((x.Answer !== undefined && x.Answer !== null && x.Answer !== "") || (x.Files !== undefined && x.Files !== null && x.Files.length > 0))?Number(1):Number(0) ).reduce((a, b) => {return a + b;})!;
    let percetageAnsweredQuestions = (answeredSectionQuestionsCount/this.questionsCount)*84
    this.progressbarService.changeTempSize(percetageAnsweredQuestions);
  }

  updateProgressBarForSection(){
    let percetageAnsweredQuestions = (this.sectionToDisplay?.Questions.length!/this.questionsCount)*84
    this.progressbarService.changeConstantSize(percetageAnsweredQuestions);
  }

  isNextButtonDisplayed() : boolean{
    return !this.isLastSection && this.sectionToDisplay !== null && this.sectionToDisplay !== undefined && this.sectionToDisplay?.Questions !== undefined && this.sectionToDisplay?.Questions?.length > 0
  }

  logOff(error: string){
    this.store.dispatch(Actions.logOff({error: error}));
  }

  updateBackground(){
    if(this.sectionToDisplay?.SectionName !== undefined)
    {
      var sectionNameFormatted = this.sectionToDisplay?.SectionName.replace(' ', '-');
      var backgroundImagePath = "./../../../assets/images/"+sectionNameFormatted+".jpg";
      this.httpService.fileExists(backgroundImagePath).subscribe((fileExists) => {
        if (fileExists) {
          document.body.style.backgroundImage = "url("+backgroundImagePath+")";
        } else {
          document.body.style.backgroundImage = "url(./../../../assets/images/background-img.jpg)";
        }
      })
    }
  }

  scrollQuestionFormTop(){
    $('html, body').animate({
      scrollTop: 150
    });
  }

  scrollTop() {
    window.document.body.scrollTo({top: 0});
  }

  refreshPage(){
    window.location.reload();
  }
  openModal(id: string) {
    this.modalService.open(id);
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }
  saveAnswer(question: Question, answer: string){
    this.store.dispatch(Actions.updateAnswer({question: question, answer: answer}));
    this.updateProgressBarForQuestion()
  }

  saveFile(question: Question, files: UploadedFile[]){
    this.store.dispatch(Actions.updateFile({question: question, files: files}));
    this.updateProgressBarForQuestion()
  }

  removeFile(question: Question, fileIndex: number){
    this.store.dispatch(Actions.removeFile({question: question, fileIndex: fileIndex}));
    this.updateProgressBarForQuestion()
  }

  confirmSection(section: Section, answer: Boolean){
    this.store.dispatch(Actions.confirmSection({section: section, answer: answer}));
  }

  continue(section: Section){
    if(this.validationService.AreNonMandatoryQuestionsAnswered(section)){
      this.submit(section);
    }
    else{
      this.openModal('empty-answers-modal');
    }
  }

  submit(section: Section){
    this.closeModal('empty-answers-modal');
    if(this.isLastSection){
      this.submitQuestionForm();
    }
    else{
      this.submitSection(section);
    }
  }

  submitSection(section: Section){
    if(this.validationService.validateSection(section)){
      this.updateProgressBarForSection();
      this.store.dispatch(Actions.submitSection({section: this.sectionToDisplay!}));
      this.checkboxRef.nativeElement.checked = false;
      this.scrollQuestionFormTop();
    }
    else{
      this.modalService.open('validation-failure-modal');
    }
  }

  submitQuestionForm(){
    let recaptchaSubscription = this.reCaptchaV3Service.execute('').subscribe(
      token => {
        console.log(`Recaptcha v3 token received`);
        this.token = token;
        this.sendSubmitRequest();
      },
      error => {
        console.log(`Recaptcha v3 error:`, error);
        this.openModal("recaptcha-failed-modal");
      }
    );
    setTimeout(() => {
      recaptchaSubscription.unsubscribe();
      if(this.token === "")
        this.openModal("recaptcha-failed-modal")
    }, 10000);
  }
  
  sendSubmitRequest(){
    var submittedAnswers = new Array<SubmittedAnswer>();
    this.sections$.forEach((sections) => {
      sections?.forEach((section) => {
        section.Questions.forEach((question) => {
          var submittedAnswer = new SubmittedAnswer(question.QuestionId, question.FieldType, question.Text, question.Answer, question.MappingType);
          if(question.FieldType === FieldType.FileUpload){
            submittedAnswer.AnswerFiles = question.Files;
          }
          submittedAnswers.push(submittedAnswer);
        });
      });
    });
    if(this.userDetails !== null){
      var submittedValidationDetails = new ValidationDetails(this.userDetails.Fullname, this.userDetails.CaseReference, this.userDetails.RegistrationNumber, this.token, environment["ValidatedEnvironment"]);
      this.store.dispatch(Actions.submitQuestionFormRequest({answers: submittedAnswers, userDetails: submittedValidationDetails}));
    }
  }
}
