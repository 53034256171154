import { Component, OnInit, Input, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import { Question} from 'src/app/models/question.model';
import { UploadedFile } from 'src/app/models/uploadedfile.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-file-upload-field',
  templateUrl: './file-upload-field.component.html',
  styleUrls: ['./file-upload-field.component.css']
})
export class FileUploadFieldComponent implements OnInit {

  files: UploadedFile[] = new Array<UploadedFile>();
  IsFileSizeExceeded: boolean = false;
  IsTooManyFiles: boolean = false;
  IsInvalidFileExtension: boolean = false;
  maxFileSizeBytes: number = Number(environment.maxFileSizeBytes);
  maxFileSizeMBytes: number = Number(environment.maxFileSizeBytes) / (1024*1024);
  maxFileCount: number = Number(environment.maxFileCount);
  allowedFileExtensions: string = environment.allowedFileExtensions;
  allowedFileExtensionsArray: string[] = environment.allowedFileExtensions.split(',');

  @Input() 
  question!: Question;

  @Output() 
  answered = new EventEmitter<any>();
  @Output() 
  removed = new EventEmitter<any>();
  
  constructor() {
  }

  ngOnInit(): void {
  }

  handleFileInput(event: Event, fileUploadHtmlElement: HTMLInputElement) {
    var fileList = (<HTMLInputElement>event.target).files!;
    if(this.isFileUploadValid(fileList)){
      for (let index = 0; index < fileList.length; index++) {
        const reader = new FileReader();
        reader.readAsDataURL(fileList?.item(index)!);
        reader.onloadend = () => {
          let fileName = fileList?.item(index)?.name!;
          let fileContent = reader.result!.toString();
          let uploadedFile = new UploadedFile(fileName, fileContent);
          this.files = [];
          this.files.push(uploadedFile);
            if(index === fileList.length-1)
              this.answerQuestion(this.question, this.files);
        }
      }
    }
    this.resetFileUploadHtmlElement(fileUploadHtmlElement);
  }

  private resetFileUploadHtmlElement(fileUploadHtmlElement: HTMLInputElement){
    const dt = new DataTransfer();
    fileUploadHtmlElement.files = dt.files;
  }

  isFileUploadValid(fileList: FileList): boolean{
    return this.isFilesSizeValid(fileList) && this.isFilesCountValid(fileList) && this.isFileExtensionValid(fileList);
  }

  isFilesSizeValid(fileList: FileList): boolean{
    this.IsFileSizeExceeded = false;
    for (let index = 0; index < fileList.length; index++) {
      if(fileList[index].size > this.maxFileSizeBytes){
        this.IsFileSizeExceeded = true;
        return false;
      }
    }
    return true;
  }

  isFilesCountValid(fileList: FileList): boolean{
    this.IsTooManyFiles = false;
    var fileCount = fileList.length + (this.question.Files? this.question.Files?.length : 0);
    var isValid = fileCount <= this.maxFileCount;
    if(isValid === false)
      this.IsTooManyFiles = true;
    return isValid;
  }

  isFileExtensionValid(fileList: FileList): boolean{
    this.IsInvalidFileExtension = false;
    for (let index = 0; index < fileList.length; index++) {
      console.log(fileList[index].name);
      if(fileList[index].name === undefined || this.allowedFileExtensionsArray.some((e) => e === '.'+fileList[index].name.split('.')[fileList[index].name.split('.').length-1]) ){
        return true;
      }
    }
    if(fileList.length > 0){
      this.IsInvalidFileExtension=true;
      return false;
    }
    return true;
  }

  removeFile(question: Question, fileIndex: number){
    this.removed.emit({question, fileIndex});
  }

  answerQuestion(question: Question, files: UploadedFile[] | null){
    this.answered.emit({question, files});
  }
}
