import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ProgressbarService } from 'src/app/services/progressbar.service';
import { ModalService } from '../_shared/_modal/modal.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-welcome-page',
  templateUrl: './welcome-page.component.html',
  styleUrls: ['./welcome-page.component.css']
})
export class WelcomePageComponent implements OnInit {
  sesionLengthMinutes: number = Number(environment.sessionLengthSec) / 60;

  constructor(private router: Router,private modalService: ModalService, private progressbarService: ProgressbarService) {
    document.body.style.backgroundImage = "url(./../../../assets/images/background-img.jpg)";
   }

  ngOnInit(): void {
    this.progressbarService.changeSize(0);
  }

  navigateToValidationForm(){
    this.router.navigateByUrl('user-validation');
  }

  openModal(id: string) {
    this.modalService.open(id);
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }

}
