<div class="col-12">
    <p attr.aria-label="{{question.Text}}">
        {{question.Text}}<span [ngClass]="question.IsMandatory? 'required-field': ''"></span>
        <app-video-buttons-small id="{{question.QuestionId}}" title="" writtenExplanation="{{question.Tooltip}}" url="{{question.VideoUrl}}" size="0"></app-video-buttons-small>
    </p>
</div>
<div class="col-12"style="padding-bottom: 20px;">
    <input type="text" class="number-text" maxlength="250" pattern="[0-9]*" ngModel #numberRef="ngModel"  [(ngModel)]="question.Answer" (keyup)="answerQuestion(question.Answer)" (keydown.enter)="answerQuestion(question.Answer)" (focusout)="answerQuestion(question.Answer)" tabindex="0">
    <div *ngIf="numberRef.errors &&(numberRef.touched || numberRef.dirty)">
        <div class="invalid" [hidden]="!numberRef.errors.pattern">
            Invalid number
        </div> 
    </div> 
    <div *ngIf="question.IsMandatory && (this.question.Answer === undefined || this.question.Answer === null || this.question.Answer.length == 0) && (numberRef.touched || numberRef.dirty)" style="text-align: left;">
        <div class="invalid">
            This field is required
        </div> 
    </div> 
</div>

