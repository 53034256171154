import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest, HttpResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { IAppState } from '../state/app.state';
import { Store } from '@ngrx/store';
import { FieldType, Question } from '../models/question.model';
import { Section } from '../models/section.model';



@Injectable({
  providedIn: 'root'
})
export class ValidationService {
  userValidated:boolean;
  constructor(private store: Store<IAppState>){ 
    this.userValidated = false;
  }

  regEmail = new RegExp("^^[a-zA-Z0-9_.-]+@[a-zA-Z0-9_.-]+[.]+[a-zA-]{2,5}$");
  regNumber = new RegExp("[0-9]*");

  validateSection(section: Section): Boolean{
    let isValid: Boolean = false;
    if(section.IsConfirmed !== undefined && section.IsConfirmed !== null && section.IsConfirmed === true){
      for (let index = 0; index < section.Questions.length; index++) {
        isValid = this.validateQuestion(section.Questions[index]);
        if(isValid === false){
          return isValid;
        }
      }
    }
    return isValid;
  }

  AreNonMandatoryQuestionsAnswered(section: Section): Boolean{
    let areAnswered: Boolean = true;
    for (let index = 0; index < section.Questions.length; index++) {
      if(!section.Questions[index].IsMandatory){
        if(this.IsQuestionNotAnswered(section.Questions[index])){
          return false
        }
      }
    }
    return areAnswered;
  }

  IsQuestionNotAnswered(question: Question): Boolean{
    if(question.FieldType === FieldType.FileUpload){
      return question.Files === undefined || question.Files === null || question.Files.length === 0;
    }
    return question.Answer === undefined || question.Answer === null || question.Answer === "";
  }

  validateQuestion(question: Question) : Boolean{
    console.log("Validating answer '"+question.Answer+"' for question: "+question.Text);
    if(question.IsMandatory && question.FieldType !== FieldType.FileUpload && (question.Answer === undefined || question.Answer === null || question.Answer === '')){
      return false;
    }

    if(!question.IsMandatory && (question.Answer === undefined || question.Answer === null || question.Answer === '')){
      return true;
    }
      
    switch (question.FieldType){
      case FieldType.Email:
        return this.isEmailValid(question.Answer);
      case FieldType.Date:
        return this.isDateBiggerThanCurrent(question.Answer);
      case FieldType.NumberText:
        return this.isNumberValid(question.Answer);
      case FieldType.Dropdown:
        return this.isDropdownValid(question);
      case FieldType.FileUpload:
        return this.isFileUploadValid(question);
      case FieldType.Checkbox:
        return this.isCheckboxValid(question);
      case FieldType.Radio:
        return this.isRadioValid(question);
    }
    return true;
  }

  isEmailValid(email: string): boolean{
    return this.regEmail.test(email);
  }

  changeDateFormat(stringDate: string): string{
    try{
      var dateParts = stringDate.split('-');
      var day = dateParts[2];
      var month = dateParts[1];
      var year = dateParts[0];
      return day + '/' + month + '/' + year;
    }
    catch{
      return stringDate;
    }
  }

  isDateBiggerThanCurrent(stringDate: string): boolean{
    
    var isValid = false;
    try{
      var dateParts = stringDate.split('-');
      var day = parseInt(dateParts[2]);
      var month = parseInt(dateParts[1])-1;
      var year = parseInt(dateParts[0]);
      var date = new Date();
      date.setDate(day);
      date.setMonth(month);
      date.setFullYear(year);
      var currentTime = new Date();
      if(date <= currentTime){
        isValid = true;
      }
    }
    finally{
      return isValid;
    }
  }

  isCheckboxValid(question: Question): boolean{
    return question.Answer === 'true';
  }

  isRadioValid(question: Question): boolean{
    return question.Answers?.map(x => x.Text).includes(question.Answer)!;
  }

  isNumberValid(numberAnswer: string): boolean{
    let regexResults = this.regNumber.exec(numberAnswer);
    if(regexResults == null)
      return false
    else
      if(regexResults[0] === numberAnswer)
        return true;
    return false;
  }

  isDropdownValid(question: Question): boolean{
    return question.Answers?.map(x => x.Text).includes(question.Answer)!;
  }

  isFileUploadValid(question: Question): boolean{
    return question.IsMandatory === false || ( question.Files !== undefined && question.Files !== null );
  }

}
