import { Injectable } from "@angular/core";
import {Router} from '@angular/router';
import {Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, of, exhaustMap, switchMap, map , tap, mergeMap} from 'rxjs';
import * as AppActions from './app.actions';
import {HttpService} from '../services/http.service';
import { ModalService } from "../components/_shared/_modal/modal.service";
import { HttpResponse } from "@angular/common/http";
import { UserValidationGuard } from "../guards/user-validation.guard";
import { Question } from "../models/question.model";
import { Section } from "../models/section.model";

@Injectable()
export class AppEffects {
    validationRequest$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AppActions.validationRequest),
            exhaustMap((action) => {
                return this.httpService
                .validateUser(action.validationDetails)
                .pipe(map((validationResponse) => 
                {
                  console.log(validationResponse.status);
                  if(validationResponse.status == 200){
                    if(validationResponse.body?.toString().includes("true")){
                      return AppActions.validationSuccess({validationDetails: action.validationDetails, validationResponse:validationResponse});
                    }
                  }
                  return AppActions.validationFailure({error: "Incorrect details.", validationResponse: validationResponse});
                }),
                catchError(error => of(AppActions.validationFailure({error: error.message, validationResponse: error})))
                );
            })
        )
    );

    validationSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AppActions.validationSuccess),
            tap(() => {
                this.userValidationGuard.userValidated = true;
                this.router.navigateByUrl('question-form');
            })
        ),
        {dispatch: false}
    );

    validationFailure$ = createEffect(() =>
    this.actions$.pipe(
            ofType(AppActions.validationFailure),
            tap(({error, validationResponse}) => {
                if(validationResponse.status == 200){
                  this.modalService.open('failed-to-authenticate-modal');
                }
                else if(validationResponse.status == 401){
                  this.modalService.open('recaptcha-failed-modal');
                }
                else{
                  this.modalService.open('bad-request-modal');
                }
            })
        ),
        {dispatch: false}
    );

    getQuestionsRequest$ = createEffect(() =>
      this.actions$.pipe(
          ofType(AppActions.getQuestionsRequest),
          exhaustMap(() => {
              return this.httpService
              .getQuestions()
              .pipe(map((getQuestionsResponse) => 
              {
                if(getQuestionsResponse.status == 200){
                  try {
                    if(getQuestionsResponse.body){
                      let questionListJson = JSON.parse(getQuestionsResponse.body.toString());
                      let sectionList: Section[] = Object.assign(new Array<Section>(), questionListJson);
                      return AppActions.getQuestionsSuccess({sections: sectionList});
                    }
                  } catch (error) {
                    return AppActions.getQuestionsFailure({error: "Error while converting section list.", getQuestionsResponse: getQuestionsResponse});
                  }
                }
                return AppActions.getQuestionsFailure({error: "Error while getting section list.", getQuestionsResponse: getQuestionsResponse});
              }),
              catchError(error => of(AppActions.getQuestionsFailure({error: error.message, getQuestionsResponse: error})))
              );
          })
      )
    );

    getQuestionsFailure$ = createEffect(() =>
    this.actions$.pipe(
            ofType(AppActions.getQuestionsFailure),
            tap(({error, getQuestionsResponse}) => {
                console.log(error);
                this.modalService.open('bad-request-modal');
            })
        ),
        {dispatch: false}
    );

    submitQuestionFormRequest$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AppActions.submitQuestionFormRequest),
            exhaustMap((action) => {
                return this.httpService
                .submitQuestionForm(action.answers, action.userDetails)
                .pipe(map((submitQuestionFormResponse) => 
                {
                  if(submitQuestionFormResponse.status == 200){
                    if(submitQuestionFormResponse.body?.toString().includes("true")){
                      return AppActions.submitQuestionFormSuccess();
                    }
                  }
                  return AppActions.submitQuestionFormFailure({error: "Form submission failed.", submitQuestionFormResponse: submitQuestionFormResponse});
                }),
                catchError(error => of(AppActions.submitQuestionFormFailure({error: error.message, submitQuestionFormResponse: error})))
                );
            })
        )
    );

    submitQuestionFormSuccess$ = createEffect(() =>
      this.actions$.pipe(
          ofType(AppActions.submitQuestionFormSuccess),
          tap(() => {
              this.router.navigateByUrl('submission-complete');
          })
      ),
      {dispatch: false}
    );

    submitQuestionFormFailure$ = createEffect(() =>
      this.actions$.pipe(
            ofType(AppActions.submitQuestionFormFailure),
            tap(({error, submitQuestionFormResponse}) => {
                console.log(error);
                if(submitQuestionFormResponse.status == 200){
                  //this.modalService.open('failed-to-authenticate-modal');
                  this.modalService.open('submit-failure-modal');
                }
                else if(submitQuestionFormResponse.status == 401){
                  this.modalService.open('recaptcha-failed-modal');
                }
                else{
                  this.modalService.open('submit-failure-modal');
                }
            })
      ),
      {dispatch: false}
    );

    logOff$ = createEffect(() =>
    this.actions$.pipe(
            ofType(AppActions.logOff),
            tap(({error}) => {
                console.log(error);
                if(error="Session expired."){
                  this.modalService.open('session-expired-modal');
                  this.userValidationGuard.userValidated = false;
                }
            })
        ),
        {dispatch: false}
    );

    constructor(private actions$: Actions, private httpService: HttpService, private router: Router, private modalService: ModalService, private userValidationGuard: UserValidationGuard) {}
}