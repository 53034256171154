export class ValidationDetails {
    public Fullname?: string;
    public CaseReference?: string;
    public RegistrationNumber?: string;
    public CaptchaResponse?: String;
    public ValidatedEnvironment?: number;

    constructor(fullname?: string, caseReference?: string, registrationNumber?: string, token?: String, validatedEnvironment?: number){
        this.Fullname = fullname;
        this.CaseReference = caseReference;
        this.RegistrationNumber = registrationNumber;
        this.CaptchaResponse = token;
        this.ValidatedEnvironment = validatedEnvironment;
    }
}