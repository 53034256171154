<div class="central">
    <div class="container" style="padding-left: 20px; padding-right: 20px; position:relative;">
        <div class="body-container">
            <br/>
            <div class="custom-title" style="padding:10px">
                THANK YOU!
            </div>
            <br/>
            <br/>
            <label class="input-label-modal" aria-label="Thank you so much. All of these details have now been passed on to the team at HF. There's no need to contact us, we'll let you know if we need anything further from you.">Thank you so much. All of these details have now been passed on to the team at HF. There's no need to contact us, we'll let you know if we need anything further from you.</label>
            <br/>
            <br/>
            <div class="custom-title" style="padding:10px">
                FREQUENTLY ASKED QUESTIONS
            </div>
            <br/>
            <br/>
            <label class="input-label-modal" aria-label="In case you have any further questions, we have included a few more videos and written explanations. Please feel free to view them, or if you're ready now, please press finish.">In case you have any further questions, we have included a few more videos and written explanations. Please feel free to view them, or if you're ready now, please press finish.</label>
            <br/>
            <br/>
            <br/>
            <br/>
            <div style="text-align: center">
                <app-video-buttons id="hf-tooltip" title="Who are HF?" writtenExplanation="HF are a firm of solicitors and we have been instructed by your insurers to investigate the incident you were involved in. In the client care letter we have sent to you, we have included information about the person handling your claim and we have also included details for their supervisor. Please read the client care letter carefully as it contains important information about the process. If you would like to know more about our firm then please visit our website at https://www.h-f.co.uk/" url="https://player.vimeo.com/video/798749827?h=446c6e97b6&autoplay=1" id="your-details-modal-video1"></app-video-buttons>    
            </div>
            <div style="text-align: center">
                <app-video-buttons id="involvement-tooltip" title="Why am I involved?" writtenExplanation="Whilst we have been instructed by your insurance company, we require additional information from you in order to properly understand what happened in the incident. In some cases, we won't require anything further from you. In other cases, we may require you to sign certain legal documents and possibly provide evidence at a final hearing. If we need you to cooperate any further, your case handler will let you know exactly what you need to do" url="https://player.vimeo.com/video/798749790?h=ba0e027bb9&autoplay=1" id="your-details-modal-video2"></app-video-buttons>    
            </div>
            <div style="text-align: center">
                <app-video-buttons id="next-tooltip" title="What happens next?" writtenExplanation="Now that you have completed this process, the information provided has been passed onto the handler dealing with your claim. Their details can be found in the client care letter we have sent to you. Please do read this carefully as it contains important information about the claim. Once the handler has considered the information you have provided, they may need to contact you. If we need you to cooperate any further, your case handler will let you know exactly what you need to do" url="https://player.vimeo.com/video/798749765?h=1d9a7f1728&autoplay=1" id="your-details-modal-video3"></app-video-buttons>    
            </div>
            <div style="text-align: center">
                <app-video-buttons id="length-tooltip" title="How long can I expect the process to take?" writtenExplanation="Unfortunately, this is a very difficult question to answer as we are reliant upon a number of factors outside of our control, such as how quickly the other lawyers involved and/or the Court are/is able progress the claim. We will, of course, contact you if we require any further information from you, or if there is anything else we need to ask you to do. You are welcome to contact your case handler at any time if you require an update" url="https://player.vimeo.com/video/798749723?h=d447343c44&autoplay=1" id="your-details-modal-video4"></app-video-buttons>           
            </div>
        </div>
        <br/>
        <br/>
        <br/>
        <br/>
        <div class="row justify-content-center">
            <div class="col-12" style="text-align: center; width:100%; bottom:20px; position:absolute;">
                <a class="btn proceed-button" href="https://www.h-f.co.uk/" aria-label="Finish" tabindex="0">Finish</a>
            </div>
        </div>
        <br/>
    </div>
</div>