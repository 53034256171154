import { Component, OnInit, Input, ViewEncapsulation, Output, EventEmitter, ViewChild, ElementRef} from '@angular/core';
import { FormControl } from '@angular/forms';
import {Observable} from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import {MatChipInputEvent} from '@angular/material/chips';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import { Question } from 'src/app/models/question.model';

@Component({
  selector: 'app-autocomplete-single-dropdown',
  templateUrl: './autocomplete-single-dropdown.component.html',
  styleUrls: ['./autocomplete-single-dropdown.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class AutocompleteSingleDropdownComponent implements OnInit {

  availableValues!:string[];
  filteredOptions!: Observable<string[]>;
  textEntered="";
  answer: string = "";
  autoCompleteControl = new FormControl();
  @Input() question!: Question;
  @Output() answered = new EventEmitter<any>();


  @ViewChild('matInput') matInput!: ElementRef<HTMLInputElement>;

  constructor() { 
  }
  
  ngOnInit(): void {
    this.filteredOptions = new Observable<string[]>();
    if( this.question.Answers)
    {
      this.availableValues = this.question.Answers.sort((a,b) => a.OrderNumber - b.OrderNumber).map(value => value.Text);
      this.filteredOptions = this.autoCompleteControl.valueChanges.pipe(startWith(''),map(value => value ? this._filter(value) : this.availableValues));
    }
  }

  private _filter(value:string):string[]{
    const filterValue = value.toLowerCase();
    return this.availableValues.filter(option => option.toLowerCase().includes(filterValue));
  }

  answerQuestion(answer: string){
    this.answered.emit({answer});
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.answer = event.option.value;
    this.answerQuestion(this.answer);
  }
}
