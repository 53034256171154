import { createFeatureSelector, createSelector } from "@ngrx/store";
import { IAppState } from "./app.state";

export const selectValidationFormFeature = createFeatureSelector<IAppState>('AppReducer');

export const isLoadingSelector = createSelector(selectValidationFormFeature, state => state.validationForm.isLoading);
export const isLoggedInSelector = createSelector(selectValidationFormFeature, state => state.validationForm.isLoggedIn);
export const errorSelector = createSelector(selectValidationFormFeature, state => state.validationForm.error);
export const validationDetailsSelector = createSelector(selectValidationFormFeature, state => state.validationForm.validationDetails);
export const sectionsSelector = createSelector(selectValidationFormFeature, state => state.questionForm.sections);
export const isSubmittingSelector = createSelector(selectValidationFormFeature, state => state.questionForm.isSubmitting);