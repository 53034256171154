import { Component, OnInit, Input, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import { Question } from 'src/app/models/question.model';
import { ValidationService } from 'src/app/services/validation.service';


@Component({
  selector: 'app-date-selector',
  templateUrl: './date-selector.component.html',
  styleUrls: ['./date-selector.component.css']
})
export class DateSelectorComponent implements OnInit {
  dateToday:string;
  @Input() question!: Question;
  @Output() answered = new EventEmitter<any>();
  constructor(private validationService: ValidationService) {
    let d = new Date();
    let dd = String(d.getDate()).padStart(2,'0');
    let mm = String(d.getMonth()+1).padStart(2,'0');
    let yyyy = d.getFullYear();
    this.dateToday = [yyyy,mm,dd].join('-');
   }

  ngOnInit(): void {
  }

  answerQuestion(answer: string){
    this.answered.emit({answer});
  }

  isValidAnswer(answer: string): boolean{
    return !this.validationService.isDateBiggerThanCurrent(answer);
  }
}
