<div style="padding-top: 100px">
<div class="central">
    <div class="container">
            <div class="body-container">
                <p class="heart-title">404 Page not found</p>
                <p class="heart-description">You have reached the custom 404 page</p>
            </div>

    </div>
    
</div>
</div>