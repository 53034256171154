<div class="col-12">
    <p attr.aria-label="{{question.Text}}">
        {{question.Text}}<span [ngClass]="question.IsMandatory? 'required-field': ''"></span>
        <app-video-buttons-small id="{{question.QuestionId}}" title="" writtenExplanation="{{question.Tooltip}}" url="{{question.VideoUrl}}" size="0"></app-video-buttons-small>
    </p>
</div>
<div class="col-12">
    <div class="checkbox-row">
        <div class="checkbox-container">
            <label class="radio-container" tabindex="0">
                <input type="checkbox" class="checkbox" [(ngModel)]="this.checkboxValue" (click)="answerQuestion(checkbox.checked);" style="display:none;" [value]="0" #checkbox/>
                <span class="checkmark" style="display: inline-block; width:25px;"></span>
            </label>
        </div> 
        <div *ngIf="question.IsMandatory && this.question.Answer !== undefined && checkbox.checked === false" style="text-align: left;">
            <div class="invalid">
                This field is required
            </div> 
        </div> 
    </div>
</div>