import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalService } from '../_shared/_modal/modal.service';
import * as Actions from '../../state/app.actions';
import { ValidationDetails } from 'src/app/models/validationdetails.model';
import { select, Store } from '@ngrx/store';
import { errorSelector, isLoadingSelector } from 'src/app/state/app.selectors';
import { Observable } from 'rxjs';
import { IAppState } from 'src/app/state/app.state';
import { HttpService } from 'src/app/services/http.service';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { environment } from 'src/environments/environment';
import { AppComponent } from 'src/app/app.component';
import { ProgressbarService } from 'src/app/services/progressbar.service';

@Component({
  selector: 'app-validation-form',
  templateUrl: './validation-form.component.html',
  styleUrls: ['./validation-form.component.css']
})
export class ValidationFormComponent implements OnInit {

  validUser = false;
  validationDetails: ValidationDetails = {};
  isLoading$: Observable<Boolean>;
  error$: Observable<String | null>;
  token: String = "";

  constructor(private router: Router, private modalService: ModalService, private reCaptchaV3Service: ReCaptchaV3Service, private store: Store<IAppState>, private httpService: HttpService, private progressbarService: ProgressbarService) { 
    this.isLoading$ = this.store.pipe(select(isLoadingSelector));
    this.error$ = this.store.pipe(select(errorSelector));
    document.body.style.backgroundImage = "url(./../../../assets/images/validation-background.jpg)";
  }

  ngOnInit(): void {
    this.progressbarService.changeSize(5);
  }

  updateProgressBarForQuestion(){
    let isFullNameAnswered = this.validationDetails.Fullname !== undefined && this.validationDetails.Fullname !== null && this.validationDetails.Fullname !== "";
    let isReferenceAnswered = this.validationDetails.CaseReference !== undefined && this.validationDetails.CaseReference !== null && this.validationDetails.CaseReference !== "";
    let isVrnAnswered = this.validationDetails.RegistrationNumber !== undefined && this.validationDetails.RegistrationNumber !== null && this.validationDetails.RegistrationNumber !== "";
    let answeredQuestionsCount = ((+isFullNameAnswered) + (+isReferenceAnswered) + (+isVrnAnswered)*3);
    this.progressbarService.changeTempSize(answeredQuestionsCount);
  }

  submitForm() {
    if(this.isValidationFormValid()){
      let recaptchaSubscription = this.reCaptchaV3Service.execute('').subscribe(
        token => {
          console.log(`Recaptcha v3 token received`);
          this.token = token;
          this.validateUser();
        },
        error => {
          console.log(`Recaptcha v3 error:`, error);
          this.token = "";
          this.openModal("recaptcha-failed-modal");
        }
      );
      setTimeout(() => {
        recaptchaSubscription.unsubscribe();
        if(this.token === "")
          this.openModal("recaptcha-failed-modal")
      }, 10000);
    }
    else{
      this.openModal("validation-failed-modal");
    }
  }

  validateUser(){
    const validationDetails = {
      Fullname: this.validationDetails.Fullname,
      CaseReference: this.validationDetails.CaseReference,
      RegistrationNumber: this.validationDetails.RegistrationNumber,
      captchaResponse: this.token,
      validatedEnvironment: environment["ValidatedEnvironment"]
    }
    this.store.dispatch(Actions.validationRequest({validationDetails}))
  }

  isValidationFormValid(): boolean{
    return (this.validationDetails.Fullname !== undefined && this.validationDetails.Fullname !== null && this.validationDetails.Fullname !== "")
      && (this.validationDetails.CaseReference !== undefined && this.validationDetails.CaseReference !== null && this.validationDetails.CaseReference !== "" )
      && (this.validationDetails.RegistrationNumber !== undefined && this.validationDetails.RegistrationNumber !== null && this.validationDetails.RegistrationNumber !== "");
  }

  openModal(id: string) {
    this.modalService.open(id);
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }

  refreshPage(){
    window.location.reload();
  }

}
