import { Action, createReducer, on } from "@ngrx/store";
import { ValidationDetails } from "src/app/models/validationdetails.model";
import { Question } from "../models/question.model";
import { Section } from "../models/section.model";
import { UploadedFile } from "../models/uploadedfile.model";
import * as Actions from "./app.actions";
import { IAppState } from "./app.state";

export const initialState: IAppState = {
    validationForm: {
        validationDetails: new ValidationDetails(),
        isLoggedIn: false,
        isLoading: false,
        error: null
    },
    questionForm: {
        sections: new Array<Section>(),
        isLoading: false,
        isSubmitting: false,
        error: null
    }
}

const _reducer = createReducer(
    initialState,
    on(Actions.validationRequest, (state) => {
        return {
            ...state,
            validationForm: {
                validationDetails: new ValidationDetails(),
                isLoggedIn: false,
                isLoading: true,
                error: null
            }
        }
    }),
    on(Actions.validationSuccess, (state, action) => {
        return {
            ...state,
            validationForm: {
                validationDetails: action.validationDetails,
                isLoggedIn: action.validationResponse.body? action.validationResponse.body?.toString().includes("true") : false,
                isLoading: false,
                error: null
            }
        }
    }),
    on(Actions.validationFailure, (state, action) => {
        return {
            ...state,
            validationForm: {
                validationDetails: new ValidationDetails(),
                isLoggedIn: false,
                isLoading: false,
                error: action.error
            }
        }
    }),
    on(Actions.getQuestionsRequest, (state) => {
        return {
            ...state,
            questionForm: {
                sections: state.questionForm.sections,
                isLoading: true,
                isSubmitting: false,
                error: null
            },
            validationForm: state.validationForm
        }
    }),
    on(Actions.getQuestionsSuccess, (state,action) => {
        action.sections.sort((a,b) => a.SectionOrder - b.SectionOrder);
        action.sections.forEach((section) => {
            section.Questions.sort((a,b) => a.OrderNumber - b.OrderNumber);
            section.Questions.forEach((question) => {
                question.Answers?.sort((a,b) => a.OrderNumber - b.OrderNumber);
            });
        });
        return {
            ...state,
            questionForm: {
                sections: action.sections,
                isLoading: false,
                isSubmitting: false,
                error: null
            },
            validationForm: state.validationForm
        }
    }),
    on(Actions.getQuestionsFailure, (state, action) => {
        return {
            ...state,
            questionForm: {
                sections: state.questionForm.sections,
                isLoading: false,
                isSubmitting: false,
                error: action.error
            },
            validationForm: state.validationForm
        }
    }),
    on(Actions.updateAnswer, (state, action) => {
        state.questionForm.sections?.forEach(section => {
            section.Questions.forEach(question => {
                if(question.QuestionId == action.question.QuestionId){
                    question.Answer = action.answer;
                }
            });
        });
        return {
            ...state,
            questionForm: state.questionForm,
            validationForm: state.validationForm
        }
    }),
    on(Actions.updateFile, (state, action) => {
        //let newState : IAppState = JSON.parse(JSON.stringify(state));
        state.questionForm.sections?.forEach(section => {
            section.Questions.forEach(question => {
                if(question.QuestionId == action.question.QuestionId){
                    if(question.Files === undefined)
                        question.Files = new Array<UploadedFile>();
                    let allFiles = question.Files?.concat(action.files);
                    question.Files = allFiles;
                }
            });
        });
        return {
            ...state,
            questionForm: state.questionForm,
            validationForm: state.validationForm
        }
    }),
    on(Actions.removeFile, (state, action) => {
        //let newState : IAppState = JSON.parse(JSON.stringify(state));
        state.questionForm.sections?.forEach(section => {
            section.Questions.forEach(question => {
                if(question.QuestionId == action.question.QuestionId){
                    question.Files?.splice(action.fileIndex, 1);
                }
            });
        });
        return {
            ...state,
            questionForm: state.questionForm,
            validationForm: state.validationForm
        }
    }),
    on(Actions.confirmSection, (state, action) => {
        //let newState : IAppState = JSON.parse(JSON.stringify(state));
        state.questionForm.sections?.forEach(section => {
            if(section.SectionId === action.section.SectionId){
                section.IsConfirmed = action.answer;
            }
        });
        return {
            ...state,
            questionForm: state.questionForm,
            validationForm: state.validationForm
        }
    }),
    on(Actions.submitSection, (state, action) => {
        let newState : IAppState = JSON.parse(JSON.stringify(state));
        newState.questionForm.sections?.forEach(section => {
            if(section.SectionId === action.section.SectionId){
                section.IsSubmitted = true;
            }
        });
        return {
            ...state,
            questionForm: newState.questionForm,
            validationForm: newState.validationForm
        }
    }),
    on(Actions.logOff, (state, action) => {
        return {
            ...state,
            validationForm: {
                validationDetails: new ValidationDetails(),
                isLoggedIn: false,
                isLoading: false,
                error: action.error
            }
        }
    }),
    on(Actions.submitQuestionFormRequest, (state) => {
        return {
            ...state,
            questionForm: {
                sections: state.questionForm.sections,
                isLoading: false,
                isSubmitting: true,
                error: null
            },
            validationForm: state.validationForm
        }
    }),
    on(Actions.submitQuestionFormSuccess, (state) => {
        return {
            ...state,
            questionForm: {
                sections: state.questionForm.sections,
                isLoading: false,
                isSubmitting: false,
                error: null
            },
            validationForm: state.validationForm
        }
    }),
    on(Actions.submitQuestionFormFailure, (state, action) => {
        return {
            ...state,
            questionForm: {
                sections: state.questionForm.sections,
                isLoading: false,
                isSubmitting: false,
                error: action.error
            },
            validationForm: state.validationForm
        }
    })
)

export function AppReducer(state: IAppState | undefined, action: Action) {
    return _reducer(state, action);
}