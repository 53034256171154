import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ModalService } from './components/_shared/_modal';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'LiabilityTriageUI';
  errorMessage = environment.errorMessage;
  isOffline = Boolean(environment.isOffline);

  constructor(private modalService: ModalService) {
    
  }

  ngAfterViewInit(): void {
    if(this.isOffline)
      this.openModal('vf-down-modal');
  }

  openModal(id: string) {
    this.modalService.open(id);
  }
}
