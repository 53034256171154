import { Component, OnInit, Input, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import { MatRadioChange } from '@angular/material/radio';
import { Observable } from 'rxjs';
import { Question } from 'src/app/models/question.model';


@Component({
  selector: 'app-radio-selector',
  templateUrl: './radio-selector.component.html',
  styleUrls: ['./radio-selector.component.css']
})
export class RadioSelectorComponent implements OnInit {
  selectedAnswer:string = 'Yes';
  @Input() question!: Question;

  @Output() answered = new EventEmitter<any>();

  constructor() {
   }

  ngOnInit(): void {
  }

  answerQuestion(answer: string){
    this.selectedAnswer = answer;
    this.answered.emit({answer: this.selectedAnswer});
  }

  selected(event: MatRadioChange): void {
    this.selectedAnswer = event.value;
    this.answerQuestion(this.selectedAnswer);
  }
}
