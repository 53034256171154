import { Component, OnInit, Input } from '@angular/core';
import { ModalService } from '../_modal';



@Component({
  selector: 'app-video-buttons-small',
  templateUrl: './video-buttons-small.component.html',
  styleUrls: ['./video-buttons-small.component.css']
})
export class VideoButtonsSmallComponent implements OnInit {
  
  @Input() url!: string;
  @Input() id!: string;
  @Input() writtenExplanation!: string;
  @Input() title!: string ;

  constructor(private modalService: ModalService) { }

  ngOnInit(): void {
  }

  openModal(id: string) {
    this.modalService.open(id);
  }

  closeModal(id: string){
    this.modalService.close(id);
  }

}
