<div class="col-12">
    <p attr.aria-label="{{question.Text}}" title="{{question.Tooltip}}">
        {{question.Text}}<span [ngClass]="question.IsMandatory? 'required-field': ''"></span>
        <app-video-buttons-small id="{{question.QuestionId}}" title="" writtenExplanation="{{question.Tooltip}}" url="{{question.VideoUrl}}" size="0"></app-video-buttons-small>
    </p>
</div>
<div class="col-12">
    <div class="radio-row">
        <div class="radio-container">
            <label class="radio-container" tabindex="0">
                <mat-radio-group class="radio-group" [(ngModel)]="this.question.Answer">
                    <mat-radio-button class="radio-button" *ngFor="let answer of this.question.Answers" [value]="answer.Text" (change)="selected($event)">
                      {{answer.Text}}
                    </mat-radio-button>
                </mat-radio-group>
            </label>
        </div> 
        <div *ngIf="question.IsMandatory && this.question.Answer !== undefined && this.question.Answer === null" style="text-align: left;">
            <div class="invalid">
                This field is required
            </div> 
        </div> 
    </div>
</div>