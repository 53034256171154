import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest, HttpResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ValidationDetails } from '../models/validationdetails.model';
import { catchError, delay, map, Observable, of } from 'rxjs';
import { IAppState } from '../state/app.state';
import { Store } from '@ngrx/store';
import { SubmittedAnswer } from '../models/submittedanswer.model';
import { ReCaptchaV3Service } from 'ng-recaptcha';



@Injectable({
  providedIn: 'root'
})
export class HttpService {
  userValidated:boolean;
  constructor(private http: HttpClient, private reCaptchaV3Service: ReCaptchaV3Service){ 
    this.userValidated = false;
  }

  validateUser(validationDetails: ValidationDetails) : Observable<HttpResponse<Object>>{
    let validationDetailsJson = JSON.stringify(validationDetails);
    return this.http.post(environment.UserValidationLookupUrl, validationDetailsJson, {headers: new HttpHeaders({ 'Ocp-Apim-Subscription-Key': environment.ApiSubscriptionKey }), responseType:'text' as 'json', observe: 'response'});
    //let test = new HttpResponse<Object>({status: 200, body: "true"});
    //return of(test);
  }

  getQuestions(): Observable<HttpResponse<Object>>{
    return this.http.get(environment.GetQuestionsLookupUrl, {headers: new HttpHeaders({ 'Ocp-Apim-Subscription-Key': environment.ApiSubscriptionKey }), responseType:'text' as 'json', observe: 'response'});
    //return this.http.get("./../../assets/LiabilityTriageQuestions.json", {responseType:'text' as 'json', observe: 'response'});
    //let test = new HttpResponse<Object>(
    //  {status: 200, 
    //    body: "[{\"SectionId\":\"1\", \"SectionOrder\":\"1\", \"SectionTooltip\":\"Section1 tooltip\", \"SectionDescription\":\"Section1 description\", \"SectionName\":\"Section1\", \"Questions\" : [{\"QuestionId\": \"1\", \"OrderNumber\": \"1\", \"Text\": \"Question1\", \"Tooltip\": \"question1 tooltip\", \"FieldType\": \"0\", \"ValidationText\": \"question1 validation text\", \"ValidationType\": \"1\", \"Answers\": [{\"OrderNumber\":\"1\", \"Text\":\"Yes\"}, {\"OrderNumber\":\"2\", \"Text\":\"No\"}]}, {\"QuestionId\": \"2\", \"OrderNumber\": \"2\", \"Text\": \"Question2\", \"Tooltip\": \"question2 tooltip\", \"FieldType\": \"6\", \"ValidationText\": \"question2 validation text\", \"ValidationType\": \"1\", \"Answers\": [{\"OrderNumber\":\"1\", \"Text\":\"Yes\"}, {\"OrderNumber\":\"2\", \"Text\":\"No\"}]}]},         {\"SectionId\":\"2\", \"SectionOrder\":\"2\", \"SectionTooltip\":\"Section2 tooltip\", \"SectionDescription\":\"Section2 description\", \"SectionName\":\"Section2\", \"Questions\" : [{\"QuestionId\": \"3\", \"OrderNumber\": \"1\", \"Text\": \"Question3\", \"Tooltip\": \"question3 tooltip\", \"FieldType\": \"0\", \"ValidationText\": \"question3 validation text\", \"ValidationType\": \"1\", \"Answers\": [{\"OrderNumber\":\"1\", \"Text\":\"Yes\"}, {\"OrderNumber\":\"2\", \"Text\":\"No\"}]}, {\"QuestionId\": \"4\", \"OrderNumber\": \"2\", \"Text\": \"Question4\", \"Tooltip\": \"question4 tooltip\", \"FieldType\": \"6\", \"ValidationText\": \"question4 validation text\", \"ValidationType\": \"1\", \"Answers\": [{\"OrderNumber\":\"1\", \"Text\":\"Yes\"}, {\"OrderNumber\":\"2\", \"Text\":\"No\"}]}]}]"
    //}); 
    //return of(test);
  }

  submitQuestionForm(submittedAnswers: SubmittedAnswer[], userDetails: ValidationDetails): Observable<HttpResponse<Object>>{
    const formData = new FormData();
    formData.append('userDetails', JSON.stringify(userDetails));
    formData.append('data', JSON.stringify(submittedAnswers));
    //{transformRequest: angular.identity, headers: {'Content-Type': undefined} } 
    return this.http.post<any>(environment.SubmitQuestionFormLookupUrl, formData, {headers: new HttpHeaders({ 'Ocp-Apim-Subscription-Key': environment.ApiSubscriptionKey }), responseType:'text' as 'json', observe: 'response'});
  }

  fileExists(url: string): Observable<boolean> {
    return this.http.get(url, { observe: 'response', responseType: 'blob' })
        .pipe(
            map(response => {
                return true;
            }),
            catchError(error => {
                console.log(error);
                return of(false);
            })
        );
  }

}
