import { HttpResponse } from '@angular/common/http';
import {createAction, props} from '@ngrx/store';
import { ValidationDetails } from 'src/app/models/validationdetails.model';
import { Question } from '../models/question.model';
import { Section } from '../models/section.model';
import { SubmittedAnswer } from '../models/submittedanswer.model';
import { UploadedFile } from '../models/uploadedfile.model';

export const validationRequest = createAction(
    '[Auth] Validation Request',
    props<{validationDetails: ValidationDetails}>()
);
export const validationSuccess = createAction(
    '[Auth] Validation Success',
    props<{validationDetails: ValidationDetails, validationResponse: HttpResponse<Object>}>()
);
export const validationFailure = createAction(
    '[Auth] Validation Failure',
    props<{error: string, validationResponse: HttpResponse<Object>}>()
);

export const getQuestionsRequest = createAction(
    '[Get] Get questions request'
);

export const getQuestionsSuccess = createAction(
    '[Get] Get questions success',
    props<{sections: Section[]}>()
);

export const getQuestionsFailure = createAction(
    '[Get] Get questions failure',
    props<{error: string, getQuestionsResponse: HttpResponse<Object>}>()
);

export const submitQuestionFormRequest = createAction(
    '[Post] Submit question form request',
    props<{answers: SubmittedAnswer[], userDetails: ValidationDetails}>()
);

export const submitQuestionFormSuccess = createAction(
    '[Post] Submit question form success'
);

export const submitQuestionFormFailure = createAction(
    '[Post] Submit question form failure',
    props<{error: string, submitQuestionFormResponse: HttpResponse<Object>}>()
);

export const updateAnswer = createAction(
    '[App] Update answer',
    props<{question: Question, answer: string}>()
);

export const updateFile = createAction(
    '[App] Update file',
    props<{question: Question, files: UploadedFile[]}>()
);

export const removeFile = createAction(
    '[App] Remove file',
    props<{question: Question, fileIndex: number}>()
);

export const confirmSection = createAction(
    '[App] Confirm Section',
    props<{section: Section, answer: Boolean}>()
);

export const submitSection = createAction(
    '[App] Submit Section',
    props<{section: Section}>()
);

export const logOff = createAction(
    '[App] Log Off',
    props<{error: string}>()
);