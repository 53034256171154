<div class="central">
    <div class="container">
        <div>
            <div class="body-container">
                <div class="customTitleFont">
                    <img id="custom-logo" src="../../assets/images/Heart-logo.png" style="margin: 10px 20px 50px 0px;"/>
                </div>
                <div class="custom-title" >
                    <label aria-label="WE WANT TO HEAR YOUR SIDE">WE WANT TO HEAR YOUR SIDE</label>
                </div>
                <br/>
                <br/>
                <label class="input-label-modal" aria-label="HF have been appointed by your insurers following an incident you were involved in.">HF have been appointed by your insurers following an incident you were involved in.</label>
                <br/>
                <br/>
                <label class="input-label-modal" aria-label="Your case has been assigned to one of our colleagues who will now review the file. To help with our enquiries and to ensure that all the information we hold is correct, we need to get a few more pieces of information from you. This process shouldn’t take longer than 10 minutes to complete.">Your case has been assigned to one of our colleagues who will now review the file. To help with our enquiries and to ensure that all the information we hold is correct, we need to get a few more pieces of information from you. This process shouldn’t take longer than 10 minutes to complete.</label>
                <br/>
                <br/>
                <label class="input-label-modal" aria-label="There is some further information at the end which explains the next steps and, if you would like to know more about who HF are and why we are contacting you, please click on either of the icons below for more information.">There is some further information at the end which explains the next steps and, if you would like to know more about who HF are and why we are contacting you, please click on either of the icons below for more information.</label>
                <br/>
                <br/>
                <label class="input-label-modal session-length-label" attr.aria-label="Please note that if you close your browser, you will have to restart the process from the beginning. Also, if you leave your browser inactive for {{this.sesionLengthMinutes}} minutes or more, your responses will be deleted and you will have to start again. As such, please allow yourself enough time before starting.">Please note that if you close your browser, you will have to restart the process from the beginning. Also, if you leave your browser inactive for {{this.sesionLengthMinutes}} minutes or more, your responses will be deleted and you will have to start again. As such, please allow yourself enough time before starting.</label>
                <br/>
                <br/>
                <label class="input-label-modal" aria-label="When you are ready, click the button below to get started.">When you are ready, click the button below to get started.</label>
                <br/>
                <br/>
                <br/>
            </div>
            
            <div class="navigation-container">
                <div>
                    <app-video-buttons id="initial-modal-video" title="I NEED MORE INFORMATION" writtenExplanation="HF have been appointed by your insurers following an incident you were involved in. Your case has been assigned to one of our colleagues who will now review the file. To help with our enquiries and to ensure that all the information we hold is correct, we need to get a few more pieces of information from you. This process shouldn’t take longer than 10 minutes to complete. There is some further information at the end which explains the next steps and, if you would like to know more about who HF are and why we are contacting you, please click on either of the icons below for more information." url="https://player.vimeo.com/video/798751372?h=7afa436ccf&autoplay=1"></app-video-buttons>
                </div>
                <br/>
                <br/>
                <div>
                    <button class="btn proceed-button" id="submit-button" (click)="navigateToValidationForm()" aria-label="Next Page" tabindex="0">
                        LET'S GET STARTED
                    </button>
                </div>
            </div>
            
            <br/>
            <br/>
        </div>
    </div>
</div>


