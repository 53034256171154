import { LocationStrategy } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AppComponent } from 'src/app/app.component';
import { ProgressbarService } from 'src/app/services/progressbar.service';

@Component({
  selector: 'app-thank-you-page',
  templateUrl: './thank-you-page.component.html',
  styleUrls: ['./thank-you-page.component.css']
})
export class ThankYouPageComponent implements OnInit {

  constructor(private locationStrategy: LocationStrategy, private progressbarService: ProgressbarService) {
    history.pushState(null, "null", window.location.href);  
    this.locationStrategy.onPopState(() => {
      history.pushState(null, "null", window.location.href);
    })
  }

  ngOnInit(): void {
    this.progressbarService.changeSize(100);
  }

}
