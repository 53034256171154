import { FieldType } from "./question.model";
import { UploadedFile } from "./uploadedfile.model";

export class SubmittedAnswer {
    public QuestionId: number;
    public QuestionText: string;
    public AnswerText?: string | null;
    public MappingType?: MappingType;
    public AnswerFiles: UploadedFile[] | null | undefined;
    public FieldType: FieldType

    constructor(questionId: number, fieldType: FieldType, questionText: string, answerText?: string, mappingType?: MappingType){
        this.QuestionId = questionId;
        this.QuestionText = questionText;
        this.AnswerText = answerText;
        this.MappingType = mappingType;
        this.FieldType = fieldType;
    }
}

export enum MappingType{
    PolicyHandlerEmail
}