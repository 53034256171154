export const environment = {
  production: false,
  errorMessage: " test message in offline mode",
  isOffline: false,
  UserValidationLookupUrl:"https://hfdigitaltest.azure-api.net/heart-liability/UserIdentification",
  GetQuestionsLookupUrl:"https://hfdigitaltest.azure-api.net/heart-liability/GetQuestions",
  SubmitQuestionFormLookupUrl:"https://hfdigitaltest.azure-api.net/heart-liability/SubmitTriage",
  maxFileSizeBytes:"10485760",
  maxFileCount:"5",
  allowedFileExtensions:".pdf,.jpeg,.jpg,.png",
  sessionLengthSec:"3600",
  captchaTimeoutMsec:"10000",
  captchaKey:"6LfaipgjAAAAAHUNqOV58GyV76UzoyjtrFsY1-SK",
  ApiSubscriptionKey:"a500cd36cd6a4857a9c40e9559612664",
  ValidatedEnvironment: 1,
  TextBoxCharactersLimit: 250,
  BigTextBoxCharactersLimit: 500
};
