import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ModalModule } from './components/_shared/_modal';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatRadioModule} from '@angular/material/radio';
import { MatChipsModule } from '@angular/material/chips';
import {MatIconModule} from '@angular/material/icon';
import {StoreModule} from '@ngrx/store';
import { HttpClientModule} from '@angular/common/http';
import { StoreDevtools, StoreDevtoolsModule } from '@ngrx/store-devtools';
import { BnNgIdleService } from 'bn-ng-idle'; 

import { AppReducer } from './state/app.reducer';
import { EffectsModule } from '@ngrx/effects';
import { AppEffects } from './state/app.effects';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { environment } from 'src/environments/environment';

import { TopBarComponent } from './components/_shared/top-bar/top-bar.component';
import { FooterComponent } from './components/_shared/footer/footer.component';
import { WelcomePageComponent } from './components/welcome-page/welcome-page.component';
import { ValidationFormComponent } from './components/validation-form/validation-form.component';
import { QuestionFormComponent } from './components/question-form/question-form.component';
import { TextFieldComponent } from './components/_shared/text-field/text-field.component';
import { NumberTextFieldComponent } from './components/_shared/number-text-field/number-text-field.component';
import { DateSelectorComponent } from './components/_shared/date-selector/date-selector.component';
import { CheckboxSelectorComponent } from './components/_shared/checkbox-selector/checkbox-selector.component';
import { AutocompleteSingleDropdownComponent } from './components/_shared/autocomplete-single-dropdown/autocomplete-single-dropdown.component';
import { BigTextFieldComponent } from './components/_shared/big-text-field/big-text-field.component';
import { UserValidationGuard } from './guards/user-validation.guard';
import { FileUploadFieldComponent } from './components/_shared/file-upload-field/file-upload-field.component';
import { EmailFieldComponent } from './components/_shared/email-field/email-field.component';
import { VideoButtonsComponent } from './components/_shared/video-buttons/video-buttons.component';
import { ThankYouPageComponent } from './components/thank-you-page/thank-you-page.component';
import { WrongRouteComponent } from './components/wrong-route/wrong-route.component';
import { RadioSelectorComponent } from './components/_shared/radio-selector/radio-selector.component';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from "ng-recaptcha";
import { VideoButtonsSmallComponent } from './components/_shared/video-buttons-small/video-buttons-small.component';

@NgModule({
  declarations: [
    AppComponent,
    WelcomePageComponent,
    ValidationFormComponent,
    QuestionFormComponent,
    TopBarComponent,
    FooterComponent,
    TextFieldComponent,
    NumberTextFieldComponent,
    DateSelectorComponent,
    CheckboxSelectorComponent,
    AutocompleteSingleDropdownComponent,
    BigTextFieldComponent,
    FileUploadFieldComponent,
    EmailFieldComponent,
    VideoButtonsComponent,
    ThankYouPageComponent,
    WrongRouteComponent,
    RadioSelectorComponent,
    VideoButtonsSmallComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ModalModule,
    FormsModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatIconModule,
    MatChipsModule,
    MatRadioModule,
    ReactiveFormsModule,
    NgbModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatIconModule,
    HttpClientModule,
    RecaptchaV3Module,
    StoreModule.forRoot({AppReducer}, {
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
      },
    }),
    EffectsModule.forRoot([AppEffects]),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
      autoPause: true,
    })
  ],
  providers: [
    UserValidationGuard, 
    BnNgIdleService,
    AppComponent,
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.captchaKey,
    }],
  bootstrap: [AppComponent]
})
export class AppModule { }
