<div class="col-12">
    <p attr.aria-label="{{question.Text}}">
        {{question.Text}}<span [ngClass]="question.IsMandatory? 'required-field': ''"></span>
        <app-video-buttons-small id="{{question.QuestionId}}" title="" writtenExplanation="{{question.Tooltip}}" url="{{question.VideoUrl}}" size="0"></app-video-buttons-small>
    </p>
</div>
<div class="col-12" style="text-align: center;">
    <input type="date" class="date-selector"  min="1900-01-01" [max]="dateToday" #datebox="ngModel" [(ngModel)]="question.Answer" (change)="answerQuestion(question.Answer);">
    <div *ngIf="question.IsMandatory && (this.question.Answer === undefined || this.question.Answer === null || this.question.Answer.length == 0) && (datebox.touched || datebox.dirty)" style="text-align: left;">
        <div class="invalid" >
            This field is required
        </div> 
    </div> 
    <div *ngIf="this.isValidAnswer(datebox.value) && (datebox.touched || datebox.dirty)" style="text-align: left;">
        <div class="invalid" >
            Invalid date
        </div> 
    </div> 
</div>
