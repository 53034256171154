import { Component, OnInit, Input, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { Question } from 'src/app/models/question.model';


@Component({
  selector: 'app-checkbox-selector',
  templateUrl: './checkbox-selector.component.html',
  styleUrls: ['./checkbox-selector.component.css']
})
export class CheckboxSelectorComponent implements OnInit {

  checkboxValue: boolean = false;

  @Input() question!: Question;

  @Output() answered = new EventEmitter<any>();

  constructor() {
   }

  ngOnInit(): void {
    this.checkboxValue = this.question.Answer !== undefined? this.question.Answer === "true" : false;
  }

  answerQuestion(answer: boolean){
    this.answered.emit({answer: String(answer)});
  }
}
