import { Component, OnInit, Input, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import { Question } from 'src/app/models/question.model';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-big-text-field',
  templateUrl: './big-text-field.component.html',
  styleUrls: ['./big-text-field.component.css']
})
export class BigTextFieldComponent implements OnInit {
  charactersLimit = Number(environment.BigTextBoxCharactersLimit);
  currentChars = 0;
  
  @Input() 
  question!: Question;

  @Output() 
  answered = new EventEmitter<any>();
  
  constructor() {
   }

  ngOnInit(): void {
  }

  answerQuestion(answer: string){
    this.answered.emit({answer});
    this.currentChars = answer.length;
  }
  
}
