<div class="explanation-container">
    <div class="explanation-text" attr.aria-label="{{title}}">
        {{title}}
    </div>
    <div class="video-buttons">
        <a class="watch-video-button title-font dropbtn" aria-label="Written Explanation" (click)="openModal('written'+id)" style="display:inline-block; text-decoration: none;" tabindex="0"><img id="info-icon" src="../../../assets/images/info.jpg"/></a>        
        <a class="watch-video-button title-font dropbtn" *ngIf="url!== undefined && url!== null && url!== ''" aria-label="Video Explanation" (click)="openModal('video'+id)" style="display:inline-block; text-decoration: none;" tabindex="0"><img id="video-icon" src="../../../assets/images/video.jpg"/></a>
    </div>
</div>
<jw-modal [id]="'video'+id" [url]="url" *ngIf="url!== undefined && url!== null && url!== ''">
    <button class="video-modal-button title-font dropbtn" aria-label="Close" (click)="closeModal('video'+id);" tabindex="0">Close</button>
</jw-modal>
<jw-modal [id]="'written'+id" url="text" class="written-explanation-modal">
    <label class="input-label" attr.aria-label="{{writtenExplanation}}" >{{writtenExplanation}}</label>
    <br/>
    <button class="video-modal-button title-font dropbtn" aria-label="Close" (click)="closeModal('written'+id);" tabindex="0">Close</button>
</jw-modal>
