import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { WelcomePageComponent } from './components/welcome-page/welcome-page.component';
import { ValidationFormComponent } from './components/validation-form/validation-form.component';
import { UserValidationGuard } from './guards/user-validation.guard';
import { QuestionFormComponent } from './components/question-form/question-form.component';
import { ThankYouPageComponent } from './components/thank-you-page/thank-you-page.component';
import { WrongRouteComponent } from './components/wrong-route/wrong-route.component';

const routes: Routes = [
  { path: 'welcome', component: WelcomePageComponent },
  { path: 'user-validation', component: ValidationFormComponent },
  { path: '',   redirectTo: '/welcome', pathMatch: 'full' },
  { path: 'question-form', component: QuestionFormComponent, canActivate: [UserValidationGuard] },
  { path: 'submission-complete', component: ThankYouPageComponent, canActivate: [UserValidationGuard] },
  { path: '**', component: WrongRouteComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
